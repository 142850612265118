<template>
    <v-progress-circular
        v-if="loading.initial"
        :size="50"
        :color="$const.color.primary"
        indeterminate
        class="d-flex mx-auto"
    ></v-progress-circular>
    <v-form v-else>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="$v.form.year.$model"
            :error-messages="getErrors('form.year')"
            label="Календарный год" 
            name="year" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-select
            v-model="$v.form.subject.$model"
            :error-messages="getErrors('form.subject')"
            required
            :items="subjects"
            label="Предмет"
        />

        <v-select
            v-model="$v.form.grade.$model"
            :disabled="!$v.form.subject.$model"
            :error-messages="getErrors('form.grade')"
            required
            :items="grades"
            label="Класс"
        />
            
        <v-select
            v-model="$v.form.category.$model"
            :error-messages="getErrors('form.category')"
            label="Категория"
            :items="categories"
            :color="$const.color.primary"
            @change="onCategoryChangeHandler"
        />

        <v-select
            v-if="form.category === 'control'"
            v-model="$v.form.collection.$model"
            :error-messages="getErrors('form.collection')"
            label="Коллекция"
            name="collection"
            hint="Используйте, чтобы объеденить задания/работы в группу. Например, контрольная `первой четверти` = коллекция 1"
            persistent-hint
            :items="collections"
            :color="$const.color.primary" 
        ></v-select>

        <v-autocomplete
            v-model="form.themes"
            label="Темы"
            :items="themes"
            :disabled="themes.length === 0"
            :color="$const.color.primary" 
            multiple
            small-chips
            deletable-chips
        ></v-autocomplete>

        <v-text-field 
            v-model="$v.form.option.$model"
            :error-messages="getErrors('form.option')"
            label="Вариант" 
            name="option" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.task.$model"
            :error-messages="getErrors('form.task')"
            label="Номер задания в варианте" 
            name="task" 
            type="number" 
            placeholder=" "
            :color="$const.color.primary" 
        ></v-text-field>

        <v-divider class="my-4" />

        <editor v-model="form.data" />

        <v-divider class="my-4" />

        <keyboard-settigns v-model="form.data.keyboard" />

        <v-divider class="my-4" />

        <v-alert v-if="serverErrors" dense type="error">
            {{ serverErrors }}
        </v-alert>

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        >
            <v-btn
                v-if="model && model.id"
                dark
                outlined
                :color="$const.color.primary"
                small
                target="_blank"
                :href="`/task/preview/${model.id}`"
            >
                <v-icon dark class="mr-2">mdi-eye</v-icon>
                Превью
            </v-btn>
        </action-buttons>
    </v-form>
</template>
<script>

import { mapState } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Editor from '@/components/task/editor/Index.vue'
import KeyboardSettigns from '@/components/task/editor/KeyboardSettings.vue'
import ActionButtons from '@/components/crud/ActionButtons.vue'

const getDefaultDataObject = () => ({
    subtasks: [],
    keyboard: {}
})
const curYear = (new Date()).getFullYear()

export default {
    components: { Editor, KeyboardSettigns, ActionButtons },
    props: {
        model: { type: Object }
    },
    provide () {
        return {
            model: this.model
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data () {
        return {
            loading: {
                initial: false
            },
            storeModule: 'task',
            themes: [],
            categories: [ {value: 'test', text: 'ВПР'}, {value: 'control', text: 'Контрольная работа'} ],
            form: {
                year: curYear,
                subject: '',
                grade: null,
                option: '',
                task: '',
                themes: [],
                sorting: '1',
                name: 'vpr_template_z',
                data: null,
                category: null,
                collection: 1
            }
        };
    },
    computed: {
        ...mapState('user', ['roles']),
        subjects () {
            return this.$store.state.app.subjects
        },
        grades () {
            if (!this.form.subject) { return [] }
            return this.subjects.find(item => item.value === this.form.subject)?.grades.flat()
        },
        collections () {
            return (new Array(10)).fill(null).map((item, index) => index + 1)
        }
    },
    async created () {
        this.loading.initial = true
        try {
            await this.$store.dispatch('app/waitUntilRequiredDataLoaded')
        } catch (e) {
            console.error(e)
        } finally {
            this.loading.initial = false
        }

        if (!this.form.data) {
            this.form.data = getDefaultDataObject()
        } else if (typeof this.form.data === 'string') {
            this.form.data = JSON.parse(this.form.data)
        }
        if (this.form.themesOfTask?.length) {
            this.form.themes = _.cloneDeep(this.form.themesOfTask)
        }
        this.fetchThemes()
    },
    validations() {
        return {
            form: {
                year: { required },
                subject: { required },
                grade: { required },
                option: { required },
                category: { required },
                collection: { required },
                task: { required }
            }
        }
    },
    methods: {
        async fetchThemes () {
            const { success, data, error } = await this.$store.dispatch('task/fetchThemes')
            if (!success) {
                console.error(error)
                return false;
            }
            this.themes = data.items?.map(item => item.name)
        },
        prepareForm (form) {

            for (let index = 0; index < form.data.subtasks.length; index++) {

                if (this.getMaxScore(form.data.subtasks[index]) <= 0) {
                    this.serverErrors = 'Суммарное количество баллов подзадания не может быть меньше 1'
                    throw new Error(this.serverErrors)
                }
            }
            form.data = JSON.stringify(form.data)
            return form
        },
        onValidationFailed () {
            window.scrollTo(0, 0)
        },

        getMaxScore (subtask) {
            return subtask.inputs.map(input => input.maxScore).reduce((prev, cur) => prev + cur, 0);
        },
        onCategoryChangeHandler () {
            if (this.form.category === 'test')
                this.form.collection = 1
        }
    }
}
</script>