<template>
    <div class="d-flex flex-column">
        <div class="d-flex">
            <div class="maxW-150 mr-4">
                <v-text-field 
                    v-model="settings.maxWidth"
                    label="Макс. ширина холста"
                    disabled
                    type="number"
                />
            </div>
            <div class="maxW-150 mr-4">
                <v-select
                    v-model="settings.draggablesPosition"
                    :items="positionsList"
                    label="Расположить элементы"
                    @change="onDraggablesPositionChanged"
                />
            </div>
            <v-checkbox
                v-model="settings.clone"
                label="Клонирование элементов при перемещении"
                @change="removeClones"
            ></v-checkbox>
            
            <v-checkbox
                v-model="settings.shuffle"
                label="Перетасовка элементов на старте"
            ></v-checkbox>
            
            <v-checkbox
                v-model="settings.resetDraggableOnCrossing"
                label="Сбрасывать элемент при наложении"
            ></v-checkbox>
        </div>
        <div class="d-flex">
            <v-checkbox
                v-model="settings.rotatable"
                label="Доступен поворот"
                hint="Только для элементов-изображений"
                persistent-hint
                :disabled="!draggablesAsImage"
                class="mr-4"
            ></v-checkbox>
            <v-text-field 
                v-model="settings.rotationCenter.left"
                label="Поворотная точка опоры по горизонтали (%)"
                type="number"
                clearable
                class="mr-4"
                :disabled="!settings.rotatable"
                @click:clear="setValue('settings.rotationCenter.left', 50)"
            />
            <v-text-field 
                v-model="settings.rotationCenter.top"
                label="Поворотная точка опоры по вертикали (%)"
                type="number"
                clearable
                :disabled="!settings.rotatable"
                @click:clear.prevent="setValue('settings.rotationCenter.top', 50)"
            />
        </div>
        <div class="d-flex">
            <v-slider
                v-model="settings.rotateButtonX"
                label="Позиция кнопки вращения по горизонтали (%)"
                :disabled="!settings.rotatable"
                dense
                thumb-label="always"
                max="90"
                min="10"
                class="my-5"
            />
        </div>

        <div class="d-flex">
            <v-slider
                v-model="settings.intersect"
                label="Чувствительность (%)"
                hint="Чувствительность холста к перемещаемым элементам. 0 - холст не проводит проверку на попадание. 100 - требуется полное вхождение."
                persistent-hint
                dense
                thumb-label="always"
                max="100"
                min="0"
                class="my-5"
            />
        </div>
        
        <media-library
            v-if="model && model.subject"
            v-model="settings.backgroundImage.src"
            :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
            label="Фоновое изображение"
            class="mt-5"
        />
        <v-alert v-else type="warning" class="my-2">
            Возможность использования медиа-библиотеки заблокирована для несохранённой формы.
            Сохраните форму и обновите страницу.
        </v-alert>

        <div class="d-flex mt-5">
            <v-text-field 
                v-model="settings.draggablesSize.width"
                label="Ширина перемещаемого элемента (px)"
                type="number"
                hint="Пустое значение активирует автоматическую адаптацию ширины"
                persistent-hint
                clearable
                class="mr-4"
            />
            <v-text-field 
                v-model="settings.draggablesSize.height"
                label="Высота перемещаемого элемента (px)"
                hint="Пустое значение активирует автоматическую адаптацию высоты"
                persistent-hint
                type="number"
                clearable
            />
        </div>
        
        <v-switch
            v-model="draggablesAsImage"
            :color="$const.color.primary"
            :label="`Тип элементов: ${draggablesAsImage ? 'Изображения' : 'Текст'}`"
            @change="changeDraggablesType"
        ></v-switch>
        
        <v-card>
            <v-card-text>
                <p class="text--primary text-h6">Перемещаемые элементы</p>
                <div
                    v-for="(item, i) in previewItems"
                    :key="i"
                    class="d-flex flex-row align-center mb-5"
                >
                    <v-text-field
                        v-if="!draggablesAsImage"
                        v-model="item.text"
                        label="Введите текст"
                        :color="$const.color.primary"
                        class="mr-4"
                        @input="updateCorrectAnswer"
                    />
                    <media-library
                        v-else-if="model && model.subject"
                        v-model="item.src"
                        :path="`${model.year}/${transliterate(model.subject.toLowerCase())}/${model.grade}`"
                        label="Изображение"
                        class="mr-4"
                        @input="updateCorrectAnswer"
                    />
                    <v-checkbox
                        v-if="settings.clone"
                        v-model="item.isClone"
                        label="Клон"
                        @change="onIsCloneUpdate"
                    ></v-checkbox>
                    <v-icon
                        class="c-pointer ml-5 "
                        style="height: 25px; width: 25px;"
                        @click="itemRemove(i)"
                    >mdi-trash-can-outline</v-icon>
                </div>
                <v-icon @click="itemAdd">mdi-plus-circle-outline</v-icon>
            </v-card-text>
        </v-card>
        <div class="mt-5">
            <v-btn @click="openPreview">Настроить позиции правильного ответа</v-btn>
        </div>
        <v-dialog v-model="showDialog" persistent max-width="900px">
            <v-card>
                <v-toolbar dark :color="$const.color.primary">
                    <v-toolbar-title>Настройка примера правильного ответа</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-subheader v-if="previewItems.length" class="pa-0">Выберите элемент для настройки:</v-subheader>
                        <div class="d-flex align-center mb-5">
                            <template v-if="draggablesAsImage">
                                <img
                                    v-for="(pItem, index) in previewItems"
                                    :key="index"
                                    :src="getUrlToImage(pItem.src)"
                                    class="draggable-list__item c-pointer mr-2"
                                    :class="{ 'draggable-list__item-selected': selectedDraggableItem === pItem }"
                                    :style="{ maxWidth: '100px' }"
                                    @click="selectDraggableItem(pItem)"
                                />
                            </template>
                            <template v-else>
                                <span
                                    v-for="(pItem, index) in previewItems"
                                    :key="index"
                                    class="draggable-list__item c-pointer px-2 mr-2"
                                    :class="{ 'draggable-list__item-selected': selectedDraggableItem === pItem }"
                                    @click="selectDraggableItem(pItem)"
                                >{{ pItem.text }}</span>
                            </template>
                        </div>

                        <v-subheader v-if="selectedDraggableItem" class="pa-0">Укажите позицию элемента:</v-subheader>
                        <div v-if="selectedDraggableItem" class="d-flex flex-column align-center">
                            <v-slider
                                v-model="selectedDraggableItem.relativeLeft"
                                label="Позиция по горизонтали (%)"
                                dense
                                thumb-label="always"
                                max="100"
                                min="-10"
                                step=".1"
                                class="w-100 mb-5"
                            />
                            <v-slider
                                v-model="selectedDraggableItem.relativeTop"
                                label="Позиция по вертикали (%)"
                                dense
                                thumb-label="always"
                                max="100"
                                min="-10"
                                step=".1"
                                class="w-100 mb-5"
                            />
                        </div>

                        <div class="w-100 pos-rel" :style="{ maxWidth: `${settings.maxWidth}px`}">
                            <img
                                v-if="settings.backgroundImage.src"
                                ref="backImage"
                                :src="getUrlToImage(settings.backgroundImage.src)"
                                :style="{ maxWidth: `${settings.maxWidth}px`}"
                                @load="onBackImageLoaded"
                            />
                            <template v-if="draggablesAsImage">
                                <img
                                    v-for="(pItem, index) in previewItems"
                                    :key="`${updateIndex}_${index}`"
                                    :src="getUrlToImage(pItem.src)"
                                    class="pos-abs"
                                    :style="{
                                        width: `${settings.draggablesSize.width}px`,
                                        height: `${settings.draggablesSize.height}px`,
                                        left: `${positionToAbsolute(pItem.relativeLeft || 0, 'width')}px`,
                                        top: `${positionToAbsolute(pItem.relativeTop || 0, 'height')}px`,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <span
                                    v-for="(pItem, index) in previewItems"
                                    :key="`${updateIndex}_${index}`"
                                    class="pos-abs"
                                    :style="{
                                        width: `${settings.draggablesSize.width}px`,
                                        height: `${settings.draggablesSize.height}px`,
                                        left: `${positionToAbsolute(pItem.relativeLeft || 0, 'width')}px`,
                                        top: `${positionToAbsolute(pItem.relativeTop || 0, 'height')}px`,
                                        background: '#ffffff78'
                                    }"
                                >{{ pItem.text }}</span>
                            </template>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined color="warning" @click.prevent.stop="resetPreview">
                        Сбросить и закрыть
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn outlined :color="$const.color.primary" @click.prevent.stop="onPreviewSave">
                        Применить и закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import MediaLibrary from '@/components/inputs/media-library'
import StringHelper from '@/plugins/string'
import { JOB_SERVER } from '@/servers'
// import TaskViewerLib from '@/libraries/task-viewer/index.js'

export default {
    mixins: [interactiveMixin],
    components: { MediaLibrary }, // , AppDragNDrop: TaskViewerLib.DragNDrop
    inject: ['model'],
    data () {
        return {
            previewItems: [],
            correctAnswer: [],
            settings: {
                maxWidth: 500,
                rotatable: false,
                rotationCenter: { left: 50, top: 50 }, // Смещение центра поворота в процентном соотношении.
                backgroundImage: { src: null }, // { src: String }
                draggablesSize: { width: 100, height: 100 },
                clone: false,
                intersect: 100,
                rotateButtonX: 90,
                shuffle: false, // Перетасовка элементов на старте
                draggablesPosition: 'bottom', // 'bottom' || 'right',
                resetDraggableOnCrossing: false
            },
            positionsList: [
                { text: 'Снизу', value: 'bottom' },
                { text: 'Справа', value: 'right' }
            ],
            draggablesAsImage: false,
            showDialog: false,
            updateIndex: 0,
            backImage: {
                width: 0,
                height: 0
            },
            selectedDraggableItem: null
        }
    },
    created () {
        if (this.correctAnswer?.length && this.correctAnswer[0]?.src) {
            this.draggablesAsImage = true
        }
        this.previewItems = _.cloneDeep(this.correctAnswer).map(item => {
            item.rotationCenter = {
                left: parseInt(this.settings.rotationCenter.left) || 0,
                top: parseInt(this.settings.rotationCenter.top) || 0
            }
            if (this.settings.clone && item.cloneFrom !== undefined) {
                item.isClone = true
            }
            return item
        })

        this.selectedDraggableItem = this.previewItems?.[0] || null
        this.onDraggablesPositionChanged()
    },
    computed: {
        transliterate () { return StringHelper.transliterate }
    },
    methods: {
        selectDraggableItem (item) {
            this.selectedDraggableItem = item
        },
        setValue (field, value) {
            setTimeout(() => {
                _.set(this, field, value)
            })
        },
        changeDraggablesType () {
            this.previewItems = []
            this.updateCorrectAnswer()
        },
        itemAdd () {
            const newItem = !this.draggablesAsImage ? ({ text: '', isClone: false }) : ({ src: null, isClone: false })
            newItem.initialPos = true
            this.previewItems.push(newItem)
            this.updateCorrectAnswer()
        },
        itemRemove (index) {
            this.previewItems.splice(index, 1)
            this.updateCorrectAnswer()
        },
        async openPreview () {
            this.showDialog = true
        },
        async sleep (timeout = 1000) {
            return new Promise (resolve => setTimeout(resolve, timeout))
        },
        closePreview () {
            this.showDialog = false
        },
        onPreviewSave () {
            this.updateCorrectAnswer()
            this.closePreview()
        },
        updateCorrectAnswer () {
            this.correctAnswer = _.cloneDeep(this.previewItems).map(item => {
                item.cloneFrom = item.isClone ? 1 : undefined
                item.inContainerId = 1
                return item
            })
        },
        resetPreview () {
            this.previewItems.forEach(cItem => {
                cItem.initialPos = true
                cItem.inContainerId = 1
                cItem.relativeLeft = 0
                cItem.relativeTop = 0
                cItem.rotation = 0
            })
            this.closePreview()
        },
        removeClones () {
            this.previewItems = this.previewItems.filter(item => !item.isClone)
            this.updateCorrectAnswer()
        },
        onIsCloneUpdate () {
            this.updateCorrectAnswer()
        },
        getUrlToImage (imagePath) {
            if (!imagePath) { return null }
            return `${JOB_SERVER}/${imagePath}`
        },
        positionToAbsolute (relativePosition, side = 'width') { // side: 'width', 'height'
            if (!this.backImage?.width) return 0
            const imageWidth = this.backImage.width
            const imageHeight = this.backImage.height
            return ((side === 'width' ? imageWidth : imageHeight) / 100) * relativePosition
        },
        onBackImageLoaded () {
            if (!this.$refs.backImage) { return }
            
            this.updateIndex++

            if (this.$refs.backImage.width > this.settings.maxWidth) {
                
                const scale = this.settings.maxWidth / this.$refs.backImage.width
                this.backImage = { width: this.settings.maxWidth, height: parseInt(this.$refs.backImage.height * scale) }
            } else {
                this.backImage = { width: this.$refs.backImage.width, height: this.$refs.backImage.height }
            }
        },
        onDraggablesPositionChanged () {
            this.settings.maxWidth = this.settings.draggablesPosition === 'bottom' ? 800 : 600
            this.onBackImageLoaded()
        }
    }
}
</script>

<style lang="scss" scoped>
.draggable-list__item {
    border: 2px solid rgb(223, 223, 223);
    border-radius: 8px;
}

.draggable-list__item-selected {
    border-color: rgb(72, 167, 255);
}
</style>