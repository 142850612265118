export const getDefaultPresets = () => ({
    dashLines: [],
    lines: [],
    labels: [],
    vertices: [],
})

export const getFigureDefaultPreset = () => ({
    width: 0, // Ширина прямоугольника/квадрата
    length: 0, // Длина прямоугольника/квадрата
    square: 0, // Площадь прямоугольника/квадрата
    perimeter: 0, // Периметр прямоугольника/квадрата
})