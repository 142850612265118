<template>
    <v-card class="pa-5">
        <div v-if="showSyntactic" class="d-flex overflow-auto">
            <div class="d-flex flex-column mr-4">
                <span class="mt-1 mb-4">Слова</span>
                <span>Члены&nbsp;предложения</span>
            </div>
            <div
                v-for="(line, lineIndex) in lines"
                :key="lineIndex"
                class="d-flex flex-column mr-4 pos-rel"
            >
                <v-chip label class="mb-2">
                    <template
                        v-for="wordIndex in line.wordsIndexes"
                    >
                        <span :key="wordIndex">
                            {{ words[wordIndex] }}&nbsp;
                        </span>
                    </template>
                </v-chip>
                <dropdown-menu
                    v-model="line.type"
                    :items="syntacticList"
                    @input="update"
                >
                    {{ line.type ? getListTitleByType(line.type, syntacticList) : 'Не указано' }}
                </dropdown-menu>
                <v-icon
                    v-if="lineIndex < lines.length - 1"
                    title="Объеденить со словом справа"
                    class="pos-abs c-pointer"
                    style="right: -16px; top: 80%; transform: translateY(-50%)"
                    small
                    @click="mergeLines(lineIndex)"
                >mdi-plus-circle-outline</v-icon>
            </div>
        </div>

        <div v-if="showMorphologic" class="d-flex overflow-auto mt-5">
            <div class="d-flex flex-column mr-4">
                <span class="mt-1 mb-4">Слова</span>
                <span>Части&nbsp;речи</span>
            </div>
            <div
                v-for="(part, partIndex) in partsOfSpeech"
                :key="partIndex"
                class="d-flex flex-column mr-4"
            >
                <v-chip label class="mb-2">
                    {{ part.word }}
                </v-chip>
                <dropdown-menu
                    v-model="part.marker"
                    :items="morphologicList"
                    @input="update"
                >
                    {{ part.marker ? getListTitleByType(part.marker, morphologicList) : 'Не указано' }}
                </dropdown-menu>
            </div>
        </div>
        <div class="mt-5 d-flex align-center">
            <v-btn
                x-small
                outlined
                @click="reset"
            >
                Сбросить настройки
            </v-btn>
            <v-spacer />
            <v-btn
                x-small
                outlined
                color="error"
                @click="remove"
            >
                Удалить вариант
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import DropdownMenu from './DropdownMenu.vue'

export default {
    components: { DropdownMenu },
    props: {
        words: { type: Array, required: true },
        value: { type: Object, default: null },
        syntacticList: { type: Array, required: true },
        showSyntactic: { type: Boolean, default: true },
        showMorphologic: { type: Boolean, default: false }
    },
    data () {
        return {
            partsOfSpeech: [], // Части речи
            lines: [], // Настройки подчеркивания линиями
            morphologicList: [ // Доступные части речи
                { title: 'Существительное', value: 'noun' },
                { title: 'Местоимение', value: 'pron' },
                { title: 'Прилагательное', value: 'adjv' },
                { title: 'Числительное', value: 'numl' },
                { title: 'Глагол', value: 'verb' },
                { title: 'Наречие', value: 'advb' },
                { title: 'Союз', value: 'conj' },
                { title: 'Предлог', value: 'prep' },
                { title: 'Частица', value: 'part' },
                { title: 'Междометие', value: 'intj' },
                { title: 'Причастие', value: 'prtc' },
                { title: 'Деепричастие ', value: 'grnd' },
                { title: 'Категория состояния', value: 'ctgr' },
                { title: 'Не указано', value: null },
            ]
        }
    },
    watch: {
        words: {
            handler () { this.reset() },
            deep: true
        }
    },
    created () {
        this.reset(false)
        if (this.value?.morphologic?.length && this.partsOfSpeech?.morphologic?.join?.(' ') !== this.value?.morphologic?.join?.(' ')) {
            this.partsOfSpeech = _.cloneDeep(this.value.morphologic)
        }
        if (this.value?.syntactic?.length) {
            this.lines = this.parseLines()
        }
    },
    methods: {
        /**
         * Сброс локальных настроек без очистки данных пропсов
         */
        reset (doUpdate = true) {
            this.lines = this.words.map((word, index) => ({ type: null, wordsIndexes: [index] }))
            this.partsOfSpeech = this.words.map(word => ({ word, marker: null }))
            doUpdate && this.update()
        },
        getListTitleByType (type, list) {
            const index = list.findIndex(l => l.value === type)
            if (index < 0) { return null }
            return list[index]?.title || null
        },
        /**
         * Объединение линий членов предложения
         */
        mergeLines (lineIndex) {
            const lextLineIndex = lineIndex + 1
            this.lines[lineIndex].wordsIndexes.push(...this.lines[lextLineIndex].wordsIndexes)
            this.lines.splice(lextLineIndex, 1)
            this.update()
        },
        prepareSyntacticValues () {
            const syntactics = []
            
            this.lines.forEach(line => {
                let joinedWords = [];

                line.wordsIndexes.forEach(wIndex => {
                    joinedWords.push(this.words[wIndex])
                })

                syntactics.push({
                    word: joinedWords.join(' '),
                    marker: line.type
                })
            })

            return syntactics
        },
        parseLines () {
            const lines = []

            this.value.syntactic.forEach((value, index) => {

                if (index > 0 && this.value.syntactic[index - 1]?.type === value.marker) {
                    this.value.syntactic[index - 1].wordsIndexes.push(index)
                }

                lines.push({
                    type: value.marker,
                    wordsIndexes: value.word.split(' ').map(lw => this.words.indexOf(lw))
                })
            })
            return lines
        },
        update () {
            this.$emit('update', {
                ...this.value,
                morphologic: this.partsOfSpeech.filter(item => !!item.marker).length === 0 ? ([]) : _.cloneDeep(this.partsOfSpeech),
                syntactic: this.prepareSyntacticValues(),
            })
        },
        /**
         * Генерируем событие удаления текущего варианта ответа
         */
        remove () {
            this.$emit('remove', this.value.id)
        }
    }
}
</script>