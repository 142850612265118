<template>
    <div>
        <v-row>
            <v-col class="d-flex flex-column">
                <v-autocomplete
                    v-model="settings.typeMarkers"
                    :items="availableSyntacticList"
                    label="Доступные члены предложения"
                    chips
                    deletable-chips
                    multiple
                    small-chips
                    item-text="title"
                ></v-autocomplete>
                <div class="d-flex">
                    <v-checkbox
                        v-model="showUserInput"
                        label="Показывать поле для ввода предложения"
                        class="mr-5"
                    />

                    <v-checkbox
                        v-model="showSyntactic"
                        label="Использовать члены предложения"
                        :disabled="!showMorphologic"
                        @change="onShowSyntacticChange"
                    />

                    <v-checkbox
                        v-model="showMorphologic"
                        label="Использовать части речи"
                        :disabled="!showSyntactic"
                        @change="onShowMorphologicChange"
                    />
                </div>

                <div class="d-flex align-center">
                    <v-text-field
                        v-if="showUserInput"
                        v-model="settings.textInputMultilineSettings.label"
                        label="Заголовок произвольного поля ввода"
                        class="mr-5"
                    />
                    <v-text-field
                        v-model="settings.textInputSettings.width"
                        label="Ширина полей ввода"
                        class="maxW-150"
                    />
                </div>
                

                <v-text-field
                    :value="initialSentence"
                    label="Исходное предложение"
                    placeholder="Введите предложение для разбора"
                    hint="Осторожно! Изменение строки сбрасывает настройки разбора предложения"
                    persistent-hint
                    @input="setInitialSentence"
                />

                <template v-if="initialSentence">
                    <variant-editor
                        v-for="(variant, index) in correctAnswer"
                        :key="variant.id"
                        :words="words"
                        :value="variant"
                        :syntactic-list="computedSyntacticList"
                        :show-syntactic="showSyntactic"
                        :show-morphologic="showMorphologic"
                        class="mt-5"
                        @update="onVariantUpdate($event, index)"
                        @remove="removeVariant"
                    />
                    <div class="mt-10">
                        <v-btn outlined @click="addVariant">+Вариант ответа</v-btn>
                    </div>
                </template>
                <p v-else class="text--secondary my-5">Для настройки интерактива введите текст "исходного предложения"</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import VariantEditor from './VariantEditor.vue'

export default {
    mixins: [interactiveMixin],
    components: { VariantEditor },
    data () {
        return {
            showSyntactic: true,
            showMorphologic: false,
            words: [],
            lastId: 0,
            initialSentence: '',
            availableSyntacticList: [ // Доступные члены предложения
                { title: 'Сказуемое', value: 'skz' },
                { title: 'Подлежащее', value: 'pod' },
                { title: 'Определение', value: 'opr' },
                { title: 'Дополнение', value: 'dop' },
                { title: 'Обстоятельство', value: 'obs' }
            ],
            correctAnswer: [],
            settings: {
                sentence: '',
                typeMarkers: ["obs", "skz", "pod", "opr", "dop"],
                textInputMultilineSettings: {
                    label: "Запишите предложение",
                },
                textInputSettings: {
                    width: "60px",
                }
            }
        }
    },
    created () {
        let types = [];
        const syntacticLength = this.correctAnswer[0]?.syntactic?.length;
        const morphologicLength = this.correctAnswer[0]?.morphologic?.length;
        if(syntacticLength === morphologicLength)
            types = ['syntactic', 'morphologic'];
        else
            types.push(syntacticLength > morphologicLength ? 'syntactic' : 'morphologic');

        types.forEach((type) => {
            if (this.correctAnswer[0]?.[type]?.length) {
                this.setInitialSentence(
                    this.correctAnswer
                        .map(variant => variant[type].map(wObject => wObject.word))
                        .flat()
                        .join(' ')
                )
            }
        })
        
        if (this.correctAnswer[0]?.morphologic?.length > 0) {
            this.showMorphologic = true
        }
        this.showSyntactic = !(this.showMorphologic && this.correctAnswer[0]?.syntactic?.length <= 0);

        // Getting highest id value
        this.lastId = this.correctAnswer.reduce((prev, cur) => cur?.id > prev ? cur.id : prev, 0)
    },
    computed: {
        showUserInput: {
            get () { return !this.settings.sentence },
            set () { this.settings.sentence = this.settings.sentence ? '' : this.initialSentence }
        },
        computedSyntacticList () {
            return this.availableSyntacticList.filter(el => this.settings.typeMarkers.includes(el.value))
        }
    },
    methods: {
        setInitialSentence (value) {
            if (value === this.initialSentence) { return }
            this.initialSentence = value
            this.words = this.initialSentence.split(' ').filter(w => !!w)
            if (!this.showUserInput) {
                this.settings.sentence = value
            }
        },
        addVariant () {
            this.correctAnswer.push({
                id: this.lastId++,
                morphologic: [],
                syntactic: [],
            })
        },
        removeVariant (id) {
            this.correctAnswer = this.correctAnswer.filter(variant => variant.id !== id)
        },
        onVariantUpdate (payload, variantIndex) {
            this.$set(this.correctAnswer, variantIndex, {
                ...payload,
                syntactic: this.showSyntactic ? payload.syntactic: [],
                morphologic: this.showMorphologic ? payload.morphologic: []
            })
        },
        onShowSyntacticChange() {
            this.correctAnswer = _.cloneDeep(this.correctAnswer).map(obj => {
                obj.syntactic = []
                obj.id += 100
                return obj
            })
        },
        onShowMorphologicChange () {
            this.correctAnswer = _.cloneDeep(this.correctAnswer).map(obj => {
                obj.morphologic = []
                obj.id += 100
                return obj
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.overflow-auto {
    width: 100%;
    overflow-x: auto;
}
</style>