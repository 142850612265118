<template>
    <div>
        <div class="d-flex">
            <v-text-field
                v-model="settings.pointsOffset"
                type="number"
                label="Размер клетки сетки (px)"
                class="maxW-150 mr-4"
            ></v-text-field>
            <v-text-field
                v-model="settings.pointsPerWidth"
                type="number"
                label="Количество клеток по горизонтали"
                class="maxW-150 mr-4"
            ></v-text-field>
            <v-text-field
                v-model="settings.pointsPerHeight"
                type="number"
                label="Количество клеток по вертикали"
                class="maxW-150"
            ></v-text-field>
        </div>
        <div class="d-flex">
            <v-checkbox
                v-model="settings.pointRound"
                label="Привязывать курсор к узлам"
                class="mt-0 mr-4"
                @change="onPointRoundChanged"
            ></v-checkbox>
            <v-text-field
                v-model="settings.segmentLength"
                type="number"
                label="Длина отрезка на клетку"
                :disabled="settings.pointRound"
                class="maxW-150 mr-4"
            ></v-text-field>
            <v-text-field
                v-model="settings.accuracy"
                type="number"
                label="Погрешность"
                hint="Погрешность равенства координат точки пользователя точке ответа"
                persistent-hint
                :disabled="settings.pointRound"
                class="maxW-150"
            ></v-text-field>
        </div>

        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                >Разметить задание</v-btn>
            </template>
            <grid-setup-editor
                :key="`${settings.pointsOffset}_${settings.pointsPerWidth}_${settings.pointsPerHeight}`"
                v-model="dialog"
                :presets="{}"
                :points.sync="correctAnswer"
                :points-offset="parseInt(settings.pointsOffset)"
                :points-per-width="parseInt(settings.pointsPerWidth)"
                :points-per-height="parseInt(settings.pointsPerHeight)"
            />
        </v-dialog>
    </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import GridSetupEditor from '@/components/task/editor/interactives/DrawOnGrid/GridSetupEditor/Index.vue'

export default {
    mixins: [interactiveMixin],
    components: { GridSetupEditor },
    data () {
        return {
            correctAnswer: [],
            dialog: false,
            settings: {
                pointsOffset: 10, //размер клетки сетки
                pointsPerWidth: 10, //количество клеток по горизонтали
                pointsPerHeight: 10, //количество клеток по вертикали
                segmentLength: 2, //>0 -фиксирована, 0 || нет - за курсором
                pointRound: false, // true - по узлам, false - произвольно
                accuracy: 0.1, //погрешность равенства координат точки пользователя точке ответа
            }
        }
    },
    created () {
        this.onPointRoundChanged()
    },
    methods: {
        onPointRoundChanged () {
            this.settings.accuracy = 0.1
            this.settings.segmentLength = this.settings.pointRound ? 0 : 1
        }
    }
}
</script>